/*-------------------- GLOBAL VARIABLES ------------------*/
:root {
  --background-color: #292929;
  --first-color: white;
  --second-color: black;
  --third-color: red;
  --sall-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  --big-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
}

/*-------------------- GENERAL HTML SETTINGS -------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background: var(--third-color);
  border-radius: 20px;
}

html {
  font-family: "Roboto", sans-serif;
}

body {
  background-color: var(--background-color) !important;
  color: var(--first-color) !important;
  overflow-x: hidden;
}

p {
  font-weight: 300;
  margin-bottom: 0px !important;
}

button {
  background: var(--third-color);
  outline: none;
  border: none;
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 15px !important;
  box-shadow: var(--sall-shadow);
}

button:hover {
  box-shadow: var(--big-shadow);
}

button:active {
  color: var(--third-color);
}

button * {
  font-weight: 500;
}

button.no-margin * {
  margin: 0px;
}

/*-------------------- GENERAL STYLE DEFINITION -------------*/

.text-left {
  text-align: left;
}

.red {
  color: var(--third-color);
}

.overflow-hidden {
  overflow: hidden;
}

.position-container {
  display: grid;
  place-items: center;
  text-align: center;
  padding-right: 0px;
}

.position {
  margin: 0px;
  background: transparent;
  border: 3px solid var(--third-color);
  width: 25px;
  height: 25px;
  border-radius: 100%;
}

.pilot-info {
  min-width: 210px;
}

.white-cell {
  white-space: nowrap;
  /* border-radius: 15px; */
  text-align: center;
  padding: 8px;
  background: var(--first-color);
  color: var(--second-color);
  box-shadow: var(--small-shadow);
  padding-top: 2px;
  padding-bottom: 2px;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
}

.red-cell {
  white-space: nowrap;
  padding: 8px;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none;
  /* border-radius: 15px; */
  text-align: center;
  background-color: var(--third-color);
  color: var(--first-color);
  font-weight: 700;
  box-shadow: var(--big-shadow);
  padding-top: 8px;
  padding-bottom: 8px;
}

.thin {
  font-weight: 300;
}

.full-width {
  width: 100% !important;
}

/*-------------------- SPECIFIC STYLE DEFINITION -------------*/

#temporary {
  color: var(--third-color);
  font-weight: 900;
  font-size: 45px;
  margin-bottom: 2rem;
}

#general-info #description {
  font-weight: 300;
}

#table-container {
  width: 100v;
  min-width: 100%;
  overflow: auto;
}

#livetiming {
  border-collapse: separate;
  /* border-spacing: 15px 8px; */
  width: 100%;
  /* margin-left: -15px; */
}

#logos * {
  align-items: center;
}

#panel {
  width: 100vw;
  max-width: 500px;
  padding: 20px;
  text-align: center;
  display: grid;
  place-content: center;
  height: inherit;
}

#panel .position {
  text-align: center;
  background-color: red;
  width: 60px;
  height: 60px;
  display: grid;
  place-content: center;
  color: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
}

.MuiPaper-root {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

@media screen and (max-width: 768px) {
  #table-container {
    width: 100vw;
    margin-left: -7px;
  }

  #event {
    margin-bottom: 2rem;
  }

  .font-small,
  .font-small * {
    font-size: 15px;
  }

  hr {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
    border-top: 1px solid var(--third-color) !important;
    opacity: 0.5;
  }

  h6 {
    margin-bottom: 1px !important;
  }

  .mobile-once {
    display: block;
  }

  #logos img {
    width: 60%;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .mobile-no-pl {
    padding-left: 0px !important;
  }

  .mobile-no-pr {
    padding-right: 0px !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .name {
    overflow: auto;
  }

  .name::-webkit-scrollbar {
    display: none;
  }

  .MuiPaper-root {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 0px;
  }
}

@media screen and (min-width: 768px) {
  .mobile-once {
    display: none;
  }

  .order-md-0 {
    order: unset !important;
  }

  .order-md-1 {
    order: unset !important;
  }
}
